<template>
  <div class="grid lg:grid-cols-3 gap-4 my-3 grid-cols-1">
    <router-link
      :to="row.route"
      v-for="(row,k) in getItems"
      :key="k"
      class="box w-full rounded-3xl p-4 flex justify-center flex-col items-center zoom-in"
    >
      <font-awesome-icon :icon="row.icon" style="width: 70px; height: 70px"/>
      <div class="mt-2 mb-1 font-bold text-base">{{ row.name }}</div>
      <div class="text-gray-500">{{ row.description }}</div>
    </router-link>
  </div>
  <router-view/>
</template>
<script>
import { computed, ref } from 'vue'
export default {
  name: 'Configuracion.vue',
  setup () {
    const items = ref([
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.documentos' },
        name: 'Documentos',
        description: 'Crear, editar y eliminar documentos',
        icon: 'book'
      },
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones' },
        name: 'Licitaciones',
        description: 'Crear, editar y eliminar Licitaciones',
        icon: 'gavel'
      },
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.logs' },
        name: 'Logs',
        description: 'Visualizacion de logs',
        icon: 'history'
      },
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.indicadores-participacion' },
        name: 'Indicadores de participación',
        description: 'analisis de indicadores y participación',
        icon: 'chart-pie'
      },
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.licitacion-indicadores' },
        name: 'Licitaciones indicadores',
        description: 'Crear, editar y eliminar indicadores',
        icon: 'chart-bar'
      },
      {
        route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.socios' },
        name: 'Socios',
        description: 'Crear, editar y eliminar Socios',
        icon: 'handshake'
      }
      // {
      //   route: { name: 'pharmasan.oportunidades.licitaciones.herramientas.licitaciones.tareas' },
      //   name: 'Tareas',
      //   description: 'Crear, editar y eliminar tareas',
      //   icon: 'handshake'
      // }
    ])
    const getItems = computed(() => items.value)
    return {
      getItems
    }
  }
}
</script>
